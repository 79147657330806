import React from 'react'
import * as styles from './article-alternate-grid.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image';
import ArticleContent from '../ArticleContent/ArticleContent';

const ArticleAlternateGrid = ({ data }) => {
    const {
        alignment,
        content,
        image,
        imageSize,
        contentRight,
        title,
        subTitle
    } = data

    return (
        <div>
            {
                title && <h3 className={styles.alternateSectionTitle}>{title}</h3>
            }
            <p className={styles.alternateSectionSubTitle}>
                {subTitle?.split('/').map((words) => (
                    <span>{words}</span>
                ))}
            </p>
            <div
                className={`
                ${styles.alternateGrid} 
                ${imageSize === 'short' ? styles.shortImage : styles.largeImage} 
                ${alignment === 'Right'
                        ? styles.alignmentRight
                        : alignment === 'Left'
                            ? ''
                            : alignment === 'Columns'
                            && styles.doubleColumns
                    }
            `}
            >
                {
                    image &&
                    <div className={styles.contentImage}>
                        <GatsbyImage image={image.gatsbyImageData} alt={`Red Rose ${image.title}`} />
                    </div>
                }
                <div className={styles.content}>
                    <ArticleContent data={{ "content": content }} />
                </div>
                {
                    contentRight &&
                    <div className={styles.contentRight}>
                        <ArticleContent data={{ "content": contentRight }} />
                    </div>
                }
            </div>

        </div>
    )
}

export default ArticleAlternateGrid