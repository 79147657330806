import React from 'react'
import ArticleAlternateGrid from '../ArticleAlternateGrid/ArticleAlternateGrid';
import ArticleContent from '../ArticleContent/ArticleContent';
import * as styles from './article-detail.module.scss'

const ArticleDetail = ({ data }) => {
    switch (data.__typename) {
        case "ContentfulComponentContent":
            return (
                <ArticleContent data={data} />
            )
        case "ContentfulComponentAlternateSection":
            return (
                <ArticleAlternateGrid data={data} />
            )
        default:
            return (
                <div>Article Details</div>
            )
    }
}

export default ArticleDetail