import React from 'react'
import * as styles from './article-content.module.scss'
import { RichText } from '../../Shared/RichText/RichText'
const ArticleContent = ({ data }) => {
    const { content } = data
    return (
        <div className={styles.articleContent}>
            <RichText content={content} />
        </div>
    )
}

export default ArticleContent