import { graphql } from 'gatsby'
import React from 'react'
import Seo from '../components/Shared/Seo/Seo'
import HeroImage from '../components/Shared/HeroImage/HeroImage'
import { useLocale, useSchema } from '../hooks'
import ArticleDetail from '../components/ArticleComponents/ArticleDetail/ArticleDetail'
import * as styles from '../styles/local.module.scss'
const Article = ({
    location,
    pageContext,
    data: {
        contentfulPageArticle: {
            id,
            contentful_id,
            seoTitle,
            seoDescription,
            sections,
            breadCrumb,
            slug,
            thumbnailImage,
            thumbnailTitle,
            createdAt,
            updatedAt
        },
        allContentfulPageArticle: {
            nodes: allLinks
        }
    }
}) => {
    const locale = useLocale()

    return (
        <div>
            <h1 style={{ display: 'none' }}>{seoTitle}</h1>

            <Seo
                title={seoTitle}
                lang={locale}
                description={seoDescription?.seoDescription}
                image={thumbnailImage}
                hrefLangs={allLinks}
                slug={slug}
                schemas={[
                    useSchema(
                        'breadcrumbs',
                        {
                            breadCrumbs: breadCrumb,
                            current: {
                                title: seoTitle,
                                slug: slug
                            }
                        }
                    ),
                    useSchema(
                        'article',
                        {
                            title: thumbnailTitle,
                            image: thumbnailImage,
                            slug: slug,
                            description: seoDescription,
                            createdAt: createdAt,
                            updatedAt: updatedAt
                        }
                    )
                ]}
            />
            <div className={styles.container}>
                {
                    sections?.map((section, index) => {
                        switch (section.__typename) {
                            case "ContentfulComponentHero":
                                return (
                                    <HeroImage data={section} key={index} />
                                )
                            default:
                                return (
                                    <div className='article-padding' key={index}>
                                        <ArticleDetail data={section} />
                                    </div>
                                )
                        }
                    })
                }
            </div>
        </div>
    )
}

export const ArticleQuery = graphql`
    query ArticlePage($id: String!, $node_locale: String!, $contentful_id: String!){
        contentfulPageArticle(id:{eq: $id}){
            id
            seoTitle
            contentful_id
            slug
            seoDescription {
                seoDescription
            }
            thumbnailImage {
                file {
                    url
                }
                width
                height
                mimeType
            }
            createdAt(locale: $node_locale, formatString: "MMMM DD, YYYY")
            updatedAt(locale: $node_locale, formatString: "MMMM DD, YYYY")
            thumbnailTitle
            sections {
                ... on ContentfulComponentHero {
                    __typename
                    title
                    subTitle
                    heroImage {
                        title
                        gatsbyImageData
                    }
                    subTitleLong {
                        subTitleLong
                    }
                }
                ... on ContentfulComponentContent {
                    __typename
                    content {
                        raw
                        references {
                            ... on ContentfulAsset {
                                contentful_id
                                title
                                description
                                gatsbyImageData(quality:80)
                                __typename
                            }
                        }
                    }
                }
                ... on ContentfulComponentAlternateSection {
                    __typename
                    imageSize
                    alignment
                    title
                    subTitle
                    content {
                        raw
                    }
                    image {
                        title
                        gatsbyImageData
                    }
                    contentRight {
                        raw
                    }
                }
            }
            breadCrumb {
                slug
                title
            }
        }
        allContentfulPageArticle(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}}){
            nodes {
                slug
                node_locale
            }
        }     
    }
`

export default Article