// extracted by mini-css-extract-plugin
export var alignmentRight = "article-alternate-grid-module--alignment-right---WZk1";
export var alternateGrid = "article-alternate-grid-module--alternate-grid--W92pU";
export var alternateSectionSubTitle = "article-alternate-grid-module--alternate-section-sub-title--qw7HD";
export var alternateSectionTitle = "article-alternate-grid-module--alternate-section-title--Di9B6";
export var content = "article-alternate-grid-module--content--NNttU";
export var contentImage = "article-alternate-grid-module--content-image--MNEkT";
export var contentRight = "article-alternate-grid-module--content-right--hdYdG";
export var doubleColumns = "article-alternate-grid-module--double-columns--6geTL";
export var largeImage = "article-alternate-grid-module--large-image---0cCg";
export var shortImage = "article-alternate-grid-module--short-image--aqXdo";